import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdjustableImageComponent } from './adjustable-image/adjustable-image.component';
import { CanvasImageDirective }     from './canvas-image.directive';

import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule }      from '@shared/material/material.module';
import { IconsModule }         from '@shared/icons/icons.module';


@NgModule({
  declarations: [
    AdjustableImageComponent,
    CanvasImageDirective
  ],
  exports:      [
    AdjustableImageComponent,
    CanvasImageDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    IconsModule
  ]
})
export class AdjustableImageModule {}
